import React, { Component} from 'react'
import {
	Container,
	Header,
	Image
} from 'semantic-ui-react'
import {
	graphql,
	Link
} from 'gatsby'
import Seo from '../components/SEO'
import Layout from '../components/Layout'
import SubCategoryListSq from '../components/SubCategoryList/SubCategorySquare'
import * as styles from './styles/pages.module.css'
import {
	mobileMaxWidth,
	resizeTimer,
	tabletMaxWidth
} from '../globalVar'


export default class BusinessPage extends Component {
	state = {
		shown: 3,
		timeOut:null
	}

	componentDidMount() {
		this.handler();

		window.addEventListener('resize', this.resizeTimerFunc);
	}

	resizeTimerFunc = () => {
		clearTimeout(this.state.timeOut1);
		const temp = setTimeout(this.handler, resizeTimer);
		this.setState({
			timeOut1:temp
		})
	}

	componentWillUnmount() {
		clearTimeout(this.state.timeOut1);
		window.removeEventListener('resize', this.resizeTimerFunc);
	}

	handler = () => {
		if (window.innerWidth > tabletMaxWidth ) {
			this.setState({
				shown:4,
			})
		} //tablet
		else if (window.innerWidth > mobileMaxWidth ) {
			this.setState({
				shown:3,
			})
		} else {  //mobile
			this.setState({
				shown:2,
			})
		}
	}

	mapBanner = (category) =>{
		return (
			<React.Fragment key={category.url}>
				{category.mainImageHref && (
					<Image className={styles.categoryBanner} as={Link} to={`/${category.url}/`} fluid src={category.mainImageHref}/>
				)}

			</React.Fragment>
		)
	}

	render() {
		const item = this.props.data.negosyoNow.viewCategory
		const pageTitle = "Negosyo"
		const location = this.props.location
		const {
			mapBanner
		} = this
		return (
			<Layout location={location} backButton={true}>
				<Seo
					title={pageTitle}
					description="Start your dream business at NegosyoNow. Choose from different starter kits and packages."
				/>
				<Container className={styles.container}>
					<Header className={styles.superHeader} >
						<h1>Find Businesses that Interest You</h1>
					</Header>
					{/* 2021-01-25 - Use banner images for mobile
					<SubCategoryListSq
						relatedcategorylist={item.children}
						shown={this.state.shown}
						reverse={true}
						contentClassName={styles.featuredBusiness}
						headerClassname={styles.featuredBusinessContent}
					/>
					*/}
					<div className={styles.desktopOnly}>
						<SubCategoryListSq
							relatedcategorylist={item.children}
							shown={this.state.shown}
							reverse={true}
							contentClassName={styles.featuredBusiness}
							headerClassname={styles.featuredBusinessContent}
						/>
					</div>
					<div className={styles.mobileOnly}>
						{item.children.map(mapBanner)}
					</div>
				</Container>
			</Layout>
		)
	}
}


export const data = graphql`
	query businessCategoryQuery {
		negosyoNow {
			viewCategory(url: "negosyo-packages") {
				name
				url
				children {
					squareImageHref
					name
					url
					mainImageHref
				}
			}
		}
	}
`
